// DON'T FORMAT ON SAVE. TRAILING SPACES ARE BEING USED FOR LINE BREAKS

export const timelineEventMarkers = {
  order: 2,
  name: "Timeline event markers",
  subcategory: "Time travel",
  markdown: `# Timeline event markers

  The timeline displays various markers to indicate relevant events. The markers provide helpful context at a glance. Use the legend below to understand each marker.

  ## Marker groups

  When there is a high concentration of events, you'll see a grouping marker. Click it to display individual markers.

  | **Marker** | **Represents** |
  |---|---|
  | {% styleableDiv styles="h-3 w-3 bg-indigo-400" /%} | Dated marker group |
  | {% styleableDiv styles="h-3 w-3 bg-gray-500" /%} | Undated marker group |

  ## Investments

  Click to {% inlineRouterLink articleId="time-travel" %}time travel{% /inlineRouterLink %} to the related date and open the {% inlineRouterLink %}investment{% /inlineRouterLink %} in {% inlineRouterLink %}Deal Hub{% /inlineRouterLink %}.

  | **Marker** | **Investment asset** | **Found in** |
  |---|---|---|
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-yellow-400" /%} | Ownership interest | {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, {% inlineRouterLink %}property pages{% /inlineRouterLink %} |
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-blue-400" /%} | Loan | {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, {% inlineRouterLink %}property pages{% /inlineRouterLink %} |

  ## Company involvements

  These markers indicate the presence of a {% inlineRouterLink %}company{% /inlineRouterLink %} in an {% inlineRouterLink %}investment{% /inlineRouterLink %} or {% inlineRouterLink articleId="what-is-a-property?" %}property{% /inlineRouterLink %}. Sometimes you'll see these markers when viewing {% inlineRouterLink %}contacts{% /inlineRouterLink %} because contacts participate in investments and properties through companies.

  | **Marker** | **Involvement type** | **Found in** | **Interaction** |
  |---|---|---|---|
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-yellow-400" /%} | Ownership interest investment | {% inlineRouterLink %}Company pages{% /inlineRouterLink %}, {% inlineRouterLink %}contact pages{% /inlineRouterLink %} | Click to select the involvement |
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-blue-400" /%} | Loan investment | {% inlineRouterLink %}Company pages{% /inlineRouterLink %}, {% inlineRouterLink %}contact pages{% /inlineRouterLink %} | Click to select the involvement |
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-teal-400" /%} | Property involvement | {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, {% inlineRouterLink %}property pages{% /inlineRouterLink %}, {% inlineRouterLink %}company pages{% /inlineRouterLink %}, {% inlineRouterLink %}contact pages{% /inlineRouterLink %} | On {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, click to visit the related {% inlineRouterLink %}property page{% /inlineRouterLink %}. On {% inlineRouterLink %}company pages{% /inlineRouterLink %} and {% inlineRouterLink %}contact pages{% /inlineRouterLink %}, click to select the involvement.  |

  ## Milestone dates

  Certain components of a property are able to keep track of {% inlineRouterLink %}milestone dates{% /inlineRouterLink %} (ex. construction proposed, construction completed). In addition to the context they provide, these dates govern when the {% inlineRouterLink %}property diagram{% /inlineRouterLink %} displays those components.

  On {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, click to visit the related {% inlineRouterLink %}property page{% /inlineRouterLink %}. On {% inlineRouterLink %}property pages{% /inlineRouterLink %}, click to {% inlineRouterLink articleId="time-travel" %}time travel{% /inlineRouterLink %}.

  | **Marker** | **Property component** | **Found in** |
  |---|---|---|
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-amber-500" /%} | {% inlineRouterLink %}Property right{% /inlineRouterLink %} | {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, {% inlineRouterLink %}property pages{% /inlineRouterLink %} |
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-cyan-400" /%} | {% inlineRouterLink %}Land covering{% /inlineRouterLink %} | {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, {% inlineRouterLink %}property pages{% /inlineRouterLink %} |
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-emerald-400" /%} | {% inlineRouterLink %}Floor area{% /inlineRouterLink %} | {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, {% inlineRouterLink %}property pages{% /inlineRouterLink %} |
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-violet-400" /%} | {% inlineRouterLink %}Enhancement{% /inlineRouterLink %} | {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %}, {% inlineRouterLink %}property pages{% /inlineRouterLink %} |

  ## Unknown dates

  In Tower Hunt, it is often useful to know that something took place even if the date is missing. These markers identify an opportunity for you to either add a missing datapoint or to {% inlineRouterLink %}unlock a datapoint{% /inlineRouterLink %}. Creating certain datapoints (ex. {% inlineRouterLink %}investments{% /inlineRouterLink %}, {% inlineRouterLink %}buildings{% /inlineRouterLink %}) **also** autogenerates a placeholder date marker to encourage users to fill in key dates.

  | **Marker** | **Represents** |
  |---|---|
  | {% styleableDiv styles="h-3 w-3 rounded-full bg-gray-500" /%} | Unknown date |`,
};
